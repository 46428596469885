import utils from '@common/utils';

const fullMenus = [{
    title: '系统首页',
    key: 'Home',
    icon: 'icon-home',
  },




  {
    title: '管理租户',
    key: 'Tenant',
    icon: ' icon-minus-cirlce'
  },
  {
    title: '管理工作人员',
    key: 'Staff',
    icon: ' icon-minus-cirlce',
  },
  {
    title: '管理场馆',
    key: 'Venue',
    icon: ' icon-minus-cirlce'
  },

  {
    title: '顾客管理',
    key: 'Customer',
    icon: 'icon-Customer-Management'
  },
  {
    title: '商品管理',
    key: 'Commodity',
    icon: 'icon-commodity',
  },


  {
    title: '营销活动',
    key: 'Marketing',
    icon: 'icon-finance',
  },

  {
    title: '优惠券',
    key: 'Coupons',
    icon: 'h-icon-link',
  },

  {
    title: 'API管理台',
    key: 'Apimanage',
    icon: 'icon-order-for-goods',
  },
  {
    title: '数据报表',
    key: 'Datareport',
    icon: 'icon-order-for-goods',
  },
  {
    title: '财务管理',
    key: 'Finmanage',
    icon: 'icon-refund',
    children: [{
        title: '销售订单',
        key: 'Finmanageorder'
      },
      {
          title: '销售订单',
          key: 'Finmanageorderclerk'
        },
      {
        title: '支付交易',
        key: 'Finmanagepay'
  
      }
    ]
  },
  {
    title: '运动处方配置',
    key: 'ExrxConfig',
    icon: ' icon-bell',
    children: [{
        title: '影响因子',
        key: 'ExrxConfigFactorList'
      },
      {
        title: '阶段设置',
        key: 'ExrxConfigStageList'

      },
      {
        title: '设备强度',
        key: 'ExrxConfigIntensityList'
      }
    ]
  },
  {
    title: '数据报表',
    key: 'Report',
    icon: 'icon-order-for-goods',
    children: [{
        title: '会员&销售',
        key: 'Daily'
      },
      {
          title: '会员签到',
          key: 'Dailysign'
        },
        {
            title: '设备使用',
            key: 'Dailydevice'
          },
          {
              title: '政府履约',
              key: 'Dailystate'
            },
      {
        title: '团课报表',
        key: 'Statistics'
  
      },
      {
        title: '核销统计(次卡)',
        key: 'CancelCountmanage'
  
      },
      {
        title: '场地预约',
        key: 'VenueReservation'
  
      },
      {
        title: '积分记录',
        key: 'PointRecord'
  
      },
      {
        title: '积分余额',
        key: 'PointBalance'
  
      },
      {
        title: '乐活日报',
        key: 'lehuodaily'
        
      },
      {
        title: '乐活月报',
        key: 'lehuoMonthly'
        
      },
      {
        title: '三高共管',
        key: 'condominium'
        
      },
      {
        title: '运动排名',
        key: 'Sportsranking'
        
      },
      
      
    ]
  },
  
  {
    title: '场馆数据',
    key: 'VenueData',
    icon: 'icon-bell',
    children: [{
        title: '在场顾客',
        key: 'AtVenueCustomer'
      },
      {
        title: '销售数据',
        key: 'SalesData'
  
      },
      {
        title: '场馆概况',
        key: 'VenueOverview'
      }
    ]
  },
  {
    title: '私教课程',
    key: 'Privatecourses',
    icon: 'icon-Customer-Management',
    children: [{
        title: '课程管理',
        key: 'Appointmentrecord'
      },
      {
        title: '上课记录',
        key: 'Classrecord'
  
      },
      
    ]
  },
  {
    title: '团课课程',
    key: 'Courseclass',
    icon: 'icon-Customer-Management',
    children: [{
        title: '排课管理',
        key: 'Coursescheduling'
      },
      {
        title: '上课记录',
        key: 'Courserecord'
  
      },
      
    ]
  },
    
    {
      title: '全屋物联',
      key: 'WholeHouseIoT',
      icon: 'h-icon-link', },
      
      
      

];

const getMenus = function(menuIdList = []) {
  return getAuthMenu(fullMenus, menuIdList);
};

let getAuthMenu = (menus, menuIdList) => {
  let configMenu = [];
  for (let menu of menus) {
    let m = utils.copy(menu);
    if (menuIdList.indexOf(m.key) > -1) {
      configMenu.push(m);
    }
    if (menu.children && menu.children.length) {
      m.children = getAuthMenu(menu.children, menuIdList);
    }
  }
  return configMenu;
};

const getKeys = function(menus) {
  let keys = [];
  for (let menu of menus) {
    keys.push(menu.key);
    if (menu.children && menu.children.length) {
      keys.push(...getKeys(menu.children));
    }
  }
  return keys;
};

let fullMenuKeys = getKeys(fullMenus);

const isAuthPage = function(menus, name) {
  if (fullMenuKeys.indexOf(name) > -1 && menus.indexOf(name) == -1) {
    return false;
  }
  return true;
};

export {
  getMenus,
  fullMenus,
  fullMenuKeys,
  isAuthPage
};
